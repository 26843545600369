import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';

@Component({
    selector: 'event-datepicker',
    templateUrl: './event-datepicker.component.html',
    styleUrls: ['./event-datepicker.component.sass'],
})

export class EventDatepickerComponent implements OnInit {
    @Input() selectedDate: moment.Moment = moment();
    @Output() dateChange = new EventEmitter<moment.Moment>(); 
    
    ngOnInit() {
        this.selectedDate = moment();
    }

    onDateChange(event: any) {
        this.selectedDate = event.value ? event.value : moment();
        this.dateChange.emit(this.selectedDate); 
    }

    nextDay() {
        this.selectedDate = this.selectedDate.add(1, 'day');
        this.dateChange.emit(this.selectedDate);
    }

    previousDay() {
        this.selectedDate = this.selectedDate.subtract(1, 'day');
        this.dateChange.emit(this.selectedDate);
    }
}