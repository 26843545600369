// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { IHALCollection } from "core-app/core/apiv3/types/hal-collection.type";
import { I18nService } from "core-app/core/i18n/i18n.service";
import { IMeeting, MeetingTypes } from "core-app/core/state/meetings/meeting.model";
import { getLastElementURL, MeetingsResourceService, numberToTime } from "core-app/core/state/meetings/meetings.service";
import { AbstractWidgetComponent } from "core-app/shared/components/grids/widgets/abstract-widget.component";
import { ToastService } from "core-app/shared/components/toaster/toast.service";
import moment from "moment";

@Component({
  templateUrl: "./meetings.component.html",
  styleUrls: ['./meetings.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetMeetingsComponent extends AbstractWidgetComponent implements OnInit, OnDestroy {
  constructor(
    protected readonly i18n: I18nService,
    protected readonly injector: Injector,
    protected readonly cdr: ChangeDetectorRef,
    public readonly meetingsService: MeetingsResourceService,
    protected readonly toastService: ToastService,
  ) {
    super(i18n, injector);
  }

  public meetingHAL: IHALCollection<IMeeting>;
  protected meetings: IMeeting[];

  ngOnInit(): void {
    this.meetingsService.list().subscribe({
      next: (meetings) => {
        this.getMeetings(meetings);
        this.cdr.detectChanges();
      },
      error: (error: HttpErrorResponse) => this.toastService.addError(error),
      complete: () => {
        this.toastService.addSuccess('Совещания загружены');
      },
    });
  }

  ngOnDestroy(): void { }

  private getMeetings(meetings: IHALCollection<IMeeting>): void {
    this.meetings = meetings._embedded.elements;
    this.meetings.forEach((value: IMeeting, index: number): void => {
      this.meetings[index].meeting_type = MeetingTypes[value.meeting_type];
      this.meetings[index]._startTime = moment(value.startTime).utc().format('DD.MM.YYYY HH:mm');
      this.meetings[index]._endTime = moment(value.endTime).utc().format('DD.MM.YYYY HH:mm');
      this.meetings[index]._duration = numberToTime(value.duration);
      this.meetings[index].project_id = getLastElementURL(value._links?.project.href!);
    });
  }
}
