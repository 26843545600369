<ng-container
  *ngIf="!this.bannersService.eeShowBanners"
>
  <wp-custom-action
      *ngFor="let action of actions; trackBy: trackByHref"
      [isFileNeeded]="isFileNeeded"
      [isFieldFilled]="isFieldFilled"
      [workPackage]="workPackage"
      [action]="action"
      class="custom-action">
  </wp-custom-action>
</ng-container>
