// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { OpSharedModule } from 'core-app/shared/shared.module';
import { OpenprojectModalModule } from 'core-app/shared/components/modal/modal.module';
import { ActivitiesEventPageComponent } from './activities-event-page.component';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivityResourceService } from 'core-app/core/state/activity/activity.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MultiCheckboxComponent } from './multi-checkbox/multi-checkbox.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { EventDatepickerComponent } from './event-datepicker/event-datepicker.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatInputModule } from '@angular/material/input';

export const EVENT_ROUTES: Ng2StateDeclaration[] = [
  {
    name: 'all_activities_events',
    parent: 'root',
    url: '/activities_events',
    component: ActivitiesEventPageComponent,
  },
];

export const EVENT_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY', // Format for parsing the input value
  },
  display: {
    dateInput: 'DD.MM.YYYY', // Format for displaying the input value
    monthYearLabel: 'MMMM YYYY', // Format for displaying the month/year label
    dateA11yLabel: 'LL',  // Accessibility label for date
    monthYearA11yLabel: 'MMMM YYYY' // Accessibility label for month/year
  }
}

@NgModule({
  imports: [
    OpSharedModule,
    OpenprojectModalModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    MatExpansionModule,
    MatIconModule,
    BrowserAnimationsModule,
    // Routes for main_screen
    UIRouterModule.forChild({ states: EVENT_ROUTES }),
  ],
  providers: [
    ActivityResourceService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: EVENT_FORMATS
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' }
  ],
  declarations: [
    EventDatepickerComponent,
    MultiCheckboxComponent,
    ActivitiesEventPageComponent,
  ],
})
export class ActivitiesEventScreenModule {
}
