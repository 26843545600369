import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { IRequestActivity, GroupedItem } from "core-app/core/state/activity/activity.model";
import { LoadingIndicatorService } from "core-app/core/loading-indicator/loading-indicator.service";
import { ActivityResourceService } from "core-app/core/state/activity/activity.service";
import { CollectionResource } from "core-app/features/hal/resources/collection-resource";
import { ToastService } from "../toaster/toast.service";
import { Subject } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { I18nService } from "core-app/core/i18n/i18n.service";
import moment from "moment";

const storageAllActivitiesEvents = 'allActivitiesEvents';
const headerBase = 'Активность';

@Component({
  selector: "activities-event-screen",
  templateUrl: "./activities-event-page.component.html",
  styleUrls: ["./activities-event-page.component.sass"],
})

export class ActivitiesEventPageComponent implements OnInit, OnDestroy {
  constructor(
    protected readonly i18n: I18nService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly activityService: ActivityResourceService,
    protected readonly toastService: ToastService,
    protected readonly loadingIndicator: LoadingIndicatorService
  ) {
  }

  public emptySelected: boolean;

  public headerDate: string | undefined;

  protected activityResurses: CollectionResource<IRequestActivity>

  protected activity: IRequestActivity;

  protected result: GroupedItem[] = [];

  protected options = [
    { label: 'Wiki', value: 'wiki_edits' },
    { label: 'Документы', value: 'documents' },
    { label: 'Затраченное время', value: 'time_entries' },
    { label: 'Наборы изменений', value: 'changesets' },
    { label: 'Новости', value: 'news' },
    { label: 'Пакеты работ', value: 'work_packages' },
    { label: 'Совещания', value: 'meetings' },
    { label: 'Форумы', value: 'messages' },
    { label: 'Атрибуты проекта', value: 'project_attributes' }
  ];

  protected filterDate: moment.Moment = moment();

  protected fetchDate: string;

  public selectedValues: string[] = [];
  public selectedValues$ = new Subject<string[]>();

  ngOnInit(): void {
    this.resultInit();
    this.getActivity(this.selectedValues, this.fetchDate);
    this.selectedValues$.subscribe((selected) => this.getActivity(selected, this.fetchDate));
  }

  ngOnDestroy(): void {
    this.selectedValues$.unsubscribe();
  }

  private resultInit() {
    const storage = window.OpenProject.guardedLocalStorage(storageAllActivitiesEvents);
    this.fetchDate = moment().format('YYYY-MM-DD');

    this.selectedValues = storage ? JSON.parse(storage).length > 0
      ? JSON.parse(storage) : this.localInit
      : this.localInit;
  }

  get localInit(): string[] {
    const all = this.options.map(item => item.value)
    window.OpenProject.guardedLocalStorage(storageAllActivitiesEvents, JSON.stringify(all));
    return all;
  }

  get parseHeaderDate() {
    return this.result.length <= 1
      ? `Активность за  ${moment(this.fetchDate, 'YYYY-MM-DD').format('DD.MM.YYYY')}`
      : headerBase;
  }

  private getActivity(params?: string[], date?: string) {
    this.result = [];
    this.emptySelected = false;
    this.loadingIndicator.indicator('events').start();

    this.activityService.list(params, date)
      .subscribe({
        next: (collection) => {
          const activity = collection.$source as IRequestActivity;
          this.result = this.activityService.getGroupedActivity(activity);
          this.result = this.sortItemsByDate(this.result);
          this.headerDate = this.parseHeaderDate;
          this.cdr.detectChanges();
        },
        error: (error: HttpErrorResponse) => {this.loadingIndicator.indicator('events').stop(); this.toastService.addError(error)},
        complete: () => {
          this.loadingIndicator.indicator('events').stop();
          this.emptySelected = this.result.length === 0;
        },
      });

  }

  sortItemsByDate(array: GroupedItem[]) {
    return array.sort((a, b) => moment(a.date, "DD.MM.YYYY").isAfter(moment(b.date, "DD.MM.YYYY")) ? -1 : 1);
  }

  onSelectedOptionsChange() { }


  onDateChange(date: moment.Moment) {
    this.fetchDate = date.format('YYYY-MM-DD');
  }

  beforePanelClosed(panel: any) {
    panel.isExpanded = false;
  }

  beforePanelOpened(panel: any) {
    panel.isExpanded = true;
  }

  selectedAll() {
    this.selectedValues = this.options.map(item => item.value);
    window.OpenProject.guardedLocalStorage(storageAllActivitiesEvents, JSON.stringify(this.selectedValues));
  }

  fetch() {
    this.selectedValues.length > 0 ? this.updateSelection(this.selectedValues) : this.toastService.addWarning('Фильтры не выбраны');
  }

  updateSelection(selected: string[]) {
    this.selectedValues$.next(selected);
    window.OpenProject.guardedLocalStorage(storageAllActivitiesEvents, JSON.stringify(selected));
    this.toastService.addSuccess('Фильтры применены');
    this.result = [];
  }

  resetSelection() {
    window.OpenProject.guardedLocalStorage(storageAllActivitiesEvents, JSON.stringify([]));
    this.headerDate = headerBase;
    this.emptySelected = true;
    this.selectedValues = [];
    this.result = [];
  }
}
