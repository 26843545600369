<widget-header [name]="widgetName" [editable]="isEditable">
  <op-widget-project-details-menu slot="menu" [resource]="resource"> </op-widget-project-details-menu>
</widget-header>

<div class="grid--widget-content">
  <edit-form *ngIf="project$ | async as project" [resource]="project">
    <div class="attributes-map">
      <div class="a-column">
        <span class="a-span" [textContent]="text.project_title"></span>
      </div>
      <div class="a-column">
        <span [textContent]="project.name"></span>
      </div>

      <ng-container *ngFor="let cf of customFields">
        <div class="attributes-map--key" [attr.title]="cf.label">
          {{ cf.label }}
          <attribute-help-text [attribute]="cf.key" [attributeScope]="'Project'"></attribute-help-text>
        </div>
        <div class="attributes-map--value">
          <op-editable-attribute-field [resource]="project" [fieldName]="cf.key"></op-editable-attribute-field>
        </div>
      </ng-container>
    </div>
  </edit-form>
</div>
