import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'multi-checkbox',
    templateUrl: './multi-checkbox.component.html',
    styleUrls: ['./multi-checkbox.component.sass']
})
export class MultiCheckboxComponent {
    @Input() options: { label: string, value: any }[] = [];

    @Input() selectedOptions: any[] = [];

    @Output() selectedOptionsChange = new EventEmitter<any[]>();

    onChange(option: { label: string, value: any }) {
        const index = this.selectedOptions.indexOf(option.value);
        index > -1 ? this.selectedOptions.splice(index, 1) : this.selectedOptions.push(option.value);
        this.selectedOptionsChange.emit(this.selectedOptions);
    }

    isSelected(option: { label: string, value: any }) {
        return this.selectedOptions.includes(option.value);
    }
}