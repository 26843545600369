import { EntityStore, StoreConfig } from '@datorama/akita';
import { ResourceState, createInitialResourceState } from 'core-app/core/state/resource-store';
import { IActivity } from './activity.model';

export interface ActivityState extends ResourceState<IActivity> {
}

@StoreConfig({ name: 'meetings' })
export class ActivityStore extends EntityStore<ActivityState> {
  constructor() {
    super(createInitialResourceState());
  }
}
