export const kpBlockField = [
    'Код проекта SAP',
    'Код проекта ИСУП',
    'Тип проекта',
    'Предприятие',
    'Цель проекта',

    'Плановая дата начала',
    'Плановая дата окончания',
    'Плановая длительность',  

    'Утвержденный бюджет проекта, тыс.руб',
    'Валютная составляющая бюджета проекта, USD',
    'PP, лет',
    'CAPEX, тыс. руб',
    'IRR, %',
    'NPV, тыс.руб.',
    'Эффект по проекту до',
    'Эффект, единицы измерения',
    'DPP, лет',
    'Вид проекта',
    'Дата начала приостановки',
    'Дата окончания приостановки',
    'Длительность приостановки',
    'Санкции',
    'Дата начала санкций',
    'Дата окончания санкций',
    'Длительность санкций',
    'ТЭО',
    'Дата начала ТЭО',
    'Дата окончания ТЭО',
    'Длительность ТЭО',
    'Тема',
    'Валютная составляющая бюджета проекта, EUR',
    'Валютная составляющая бюджета проекта, CNY',
]

export const kpBlockFieldByClose = [
    'Описание проекта',
    'Ответственный эксперт КСП',
    'Ответственный ВИТ',
    'Ответственный менеджер СУОФ',
    'Ответственный отдела проектирования',
    'Код СОВА',
    'Привязка к ОР',
    'Дата начала ОР',

    'Плановая дата начала',
    'Плановая дата окончания',
    'Плановая длительность',  
    'Ручное планирование',
    'Игнорировать нерабочие дни',
]

/*export const kpNoBlockFieldAdmin = [
    'Дата начала приостановки',
    'Дата окончания приостановки',
    'Длительность приостановки',
    'Дата начала санкций',
    'Дата окончания санкций',
    'Длительность санкций',
    'Привязка к ОР',
    'Дата начала ОР',
]*/

export const workBlockField =[
    'Код проекта ИСУП',
    'Тема',
    'Критический путь',
    'Плановая дата начала',
    'Плановая дата окончания',
    'Плановая длительность',
    'Ручное планирование',
    'Игнорировать нерабочие дни',
    'Приоритет',
]

export const workBlockFieldByClose = [
    'Фактическая дата начала', 
    'Фактическая дата окончания',
    'Фактическая длительность',
    'Ручное планирование',
    'Игнорировать нерабочие дни',
    'Исполнитель',
]

export const workPauseBlockFieldByPause = [
    'Фактическая дата начала', 
]

export const allBlockField = kpBlockField.concat(kpBlockFieldByClose);
export const workCloseBlockField = workBlockField.concat(workBlockFieldByClose);
export const workPauseBlockField = workBlockField.concat(workPauseBlockFieldByPause);
