import { Injectable } from '@angular/core';
import { HookService } from 'core-app/features/plugins/hook-service';
import { WidgetRegistration } from '../grid/grid-const-helper';

@Injectable()
export class GridWidgetsService {
  constructor(private Hook:HookService) {}

  public get registered() {
    let registeredWidgets:WidgetRegistration[] = [];

    _.each(this.Hook.call('gridWidgets'), (registration:WidgetRegistration[]) => {
      registeredWidgets = registeredWidgets.concat(registration);
    });
    return registeredWidgets;
  }

  public lkRegisteredWidgets(flag: boolean) {
    let registered:WidgetRegistration[] = [];
    const name = flag ? 'gridWidgetsManager' : 'gridWidgetsWorker'

    _.each(this.Hook.call(name), (registration:WidgetRegistration[]) => {
      registered = registered.concat(registration);
    });    
    return registered;
  }

  public get mainRegisteredWidgets() {
    let registered:WidgetRegistration[] = [];
    _.each(this.Hook.call('gridWidgetsMain'), (registration:WidgetRegistration[]) => {
      registered = registered.concat(registration);
    });    
    return registered;
  }  
}
