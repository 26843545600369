import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { I18nService } from 'core-app/core/i18n/i18n.service';
import { Title } from '@angular/platform-browser';
import { GridInitializationService } from 'core-app/shared/components/grids/grid/initialization.service';
import { PathHelperService } from 'core-app/core/path-helper/path-helper.service';
import { GridResource } from 'core-app/features/hal/resources/grid-resource';
import { ConfigurationService } from 'core-app/core/config/configuration.service';
import { GRID_PROVIDERS } from '../grid-const-helper';
import { GridAreaService } from '../page-service/area.service';
import { GridAddWidgetService } from '../page-service/add-widget.service';
import { ConfigQueryService } from 'core-app/core/apiv3/endpoints/queries/query-service';
import { mainGrid, proejctMain } from '../config-lk-widget';

@Component({
  selector: "main-screen",
  templateUrl: "./main-page.component.html",
  styleUrls: ["./main-page.component.sass"],
  providers: GRID_PROVIDERS,
  encapsulation: ViewEncapsulation.None,
})
export class MainPageComponent implements OnInit, OnDestroy {
  public text = {
    title: this.mainGridName,
    html_title: this.mainGridName,
  };

  public showToolbar = true;
  private apiV3Service = this.gridInitialization.apiV3Service;

  constructor(
    readonly gridInitialization: GridInitializationService,
    // not used in the base class but will be used throughout the subclasses
    readonly pathHelper: PathHelperService,
    readonly i18n: I18nService,
    readonly cdRef: ChangeDetectorRef,
    readonly title: Title,
    readonly addWidget: GridAddWidgetService,
    readonly renderer: Renderer2,
    readonly areas: GridAreaService,
    readonly configurationService: ConfigurationService,
    readonly configService: ConfigQueryService
  ) { }

  public grid: GridResource;
  public modify: boolean;

  protected isTurboFrameSidebarEnabled(): boolean {
    // may be overridden by subclasses
    return false;
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'widget-grid-layout');
    this.configService.registedMainWidgets();
    this.getUserRole();
    this.getInit();
  }

  getInit() {
    this
    .gridInitialization
    .initialize(this.gridScopePath())
    .subscribe((grid) => {
      this.grid = grid;
      this.cdRef.detectChanges();
    });   
  }

  protected get mainGridName():string {
    return mainGrid;
  }

  protected gridScopePath(): string {
    return this.pathHelper.projectPath(proejctMain);
  }

  getUserRole() {
    this.apiV3Service.users.getCurrentUser().subscribe((user) => {
      this.modify = user.admin;
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'widget-grid-layout');
  }
}
