
<div id="div-values-begin-{{filter.id}}" class="inline-label">
  <label for="values-begin-{{filter.id}}"
         [textContent]="labelBegin"
         class="advanced-filters--affix">
  </label>
  <input [(ngModel)]="begin"
         [opAutofocus]="shouldFocus"
         required
         class="advanced-filters--number-field"
         id="values-begin-{{filter.id}}"
         name="v[{{filter.id}}]"
         type="number" />

</div>
<div id="div-values-end-{{filter.id}}" class="inline-label">
  <label for="values-end-{{filter.id}}"
         [textContent]="labelEnd"
         class="advanced-filters--affix">
  </label>
  <input [(ngModel)]="end"
         [opAutofocus]="shouldFocus"
         required
         class="advanced-filters--number-field"
         id="values-end-{{filter.id}}"
         name="v[{{filter.id}}]"
         type="number" />
</div>