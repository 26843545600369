<li
  class="spot-list--item"
  *ngFor="let project of filteredProjects; index as i; first as isFirst; last as isLast"
  data-test-selector="op-header-project-select--item"
  [attr.data-list-selector]="projectListItemIdentifier"
>
  <a
    tabindex="-1"
    *ngIf="!project.disabled"
    class="spot-list--item-action"
    [ngClass]="{
      'spot-list--item-action_disabled': project.disabled,
      'spot-list--item-action_active': (searchableProjectListService.selectedItemID$ | async) === project.id
    }"
    [href]="extendedUrl(project.id)"
    [attr.data-list-selector]="projectListActionIdentifier"
    [attr.data-project-id]="project.id"
    [attr.data-test-selector]="currentProjectService.id === project.id.toString() ? 'op-header-project-select--active-item' : null"
    title="{{ project.name }}"
  >
    <span
      class="spot-list--item-title spot-list--item-title_ellipse-text"
      data-test-selector="op-header-project-select--item-title"
    >
      <span
        [opSearchHighlight]="searchText"
        [textContent]="project.name"></span>

      <span *ngIf="project.deepChildrenCount > 0" class="badge -secondary">{{ project.deepChildrenCount }}</span>
      <svg
        *ngIf="favored?.includes(project.id.toString())"
        star-fill-icon
        class="op-primer--star-icon"
        size="small"
      ></svg>
    </span>

    <a
      [href]="extendedUrl(null)"
      *ngIf="currentProjectService.id === project.id.toString()"
      data-test-selector="op-header-project-select--item-remove-icon"
    >
      <svg
        x-circle-icon
        size="small"
      ></svg></a>
  </a>
  <span
    *ngIf="project.disabled"
    class="spot-list--item-action spot-list--item-action_disabled"
    [ngClass]="{
      'spot-list--item-action_active': (searchableProjectListService.selectedItemID$ | async) === project.id
    }"
  >
    <span
      class="spot-list--item-title spot-list--item-title_ellipse-text"
      data-test-selector="op-header-project-select--item-disabled-title"
    >{{ project.name }}</span>
  </span>

  <ul
    *ngIf="project.children.length"
    op-header-project-select-list
    [projects]="project.children"
    [displayMode]="displayMode"
    [favored]="favored"
    [selected]="selected"
    [searchText]="searchText"
  ></ul>
</li>
