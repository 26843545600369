<op-notifications-settings-toolbar></op-notifications-settings-toolbar>
<form
  [formGroup]="form"
  (ngSubmit)="saveChanges()"
  class="op-form"
>
  <div class="op-form--section-header">
    <h3 class="op-form--section-header-title">{{ text.notifyImmediately.title }}</h3>
    <p>{{ text.notifyImmediately.description }}</p>
  </div>

  <spot-selector-field
    class="op-notifications-settings--bold-label"
    [label]="text.mentioned.title">
      <input
        disabled
        checked
        type="checkbox"
        slot="input"
      />
  </spot-selector-field>

  <spot-selector-field
    [label]="text.watched"
    [control]="form.get('watched')">
    <input
      disabled
      checked
      type="checkbox"
      slot="input"
    />
  </spot-selector-field>

  <spot-selector-field
    [label]="text.assignee"
    [control]="form.get('assignee')"
  >
    <input
      slot="input"
      type="checkbox"
      formControlName="assignee"
      data-qa-global-notification-type="assignee"
    />
  </spot-selector-field>

  <!--<spot-selector-field
    [label]="text.responsible"
    [control]="form.get('responsible')"
  >
    <input
      slot="input"
      type="checkbox"
      formControlName="responsible"
      data-qa-global-notification-type="responsible"
    />
  </spot-selector-field>-->

  <spot-selector-field
    [label]="text.shared"
    [control]="form.get('shared')"
  >
    <input
      slot="input"
      type="checkbox"
      formControlName="shared"
      data-qa-global-notification-type="shared"
    />
  </spot-selector-field>

  <div class="op-form--section-header">
    <h3 class="op-form--section-header-title">{{ text.dateAlerts.title }}</h3>
    <p>{{ text.dateAlerts.description }}</p>
  </div>

  <div *ngIf="!eeShowBanners">
    <div
      class="op-reminder-settings-date-alerts--row"
      formGroupName="startDate"
    >
      <input
        type="checkbox"
        formControlName="active"
        class="op-reminder-settings-date-alerts--active"
        data-qa-global-notification-type="op-settings-start-date-active"
        id="op-settings-start-date"
      />
      <label
        class="op-reminder-settings-date-alerts--label"
        [textContent]="text.startDate"
        for="op-settings-start-date"
      ></label>
      <select
        formControlName="time"
        class="op-reminder-settings-date-alerts--time form--select -narrow"
        data-qa-global-notification-type= "op-reminder-settings-start-date-alerts"
        required="true"
        *ngIf="dateAlertsStatuses.startDate"
      >
        <option
          *ngFor="let availableTime of availableTimes"
          [value]="availableTime.value"
        >
          {{availableTime.title}}
        </option>
      </select>
    </div>

    <div
      class="op-reminder-settings-date-alerts--row"
      formGroupName="dueDate"
    >
      <input
        type="checkbox"
        formControlName="active"
        class="op-reminder-settings-date-alerts--active"
        data-qa-global-notification-type= "op-settings-due-date-active"
        id="op-settings-due-date"
      />
      <label
        class="op-reminder-settings-date-alerts--label"
        [textContent]="text.dueDate"
        for="op-settings-due-date"
      ></label>
      <select
        formControlName="time"
        class="op-reminder-settings-date-alerts--time form--select -narrow"
        data-qa-global-notification-type= "op-reminder-settings-due-date-alerts"
        required="true"
        *ngIf="dateAlertsStatuses.dueDate"
      >
        <option
          *ngFor="let availableTime of availableTimes"
          [value]="availableTime.value">
          {{availableTime.title}}
        </option>
      </select>
    </div>

    <div
      class="op-reminder-settings-date-alerts--row"
      formGroupName="overdue"
    >
      <input
        formControlName="active"
        type="checkbox"
        class="op-reminder-settings-date-alerts--active"
        data-qa-global-notification-type="op-settings-overdue-date-active"
        id="op-settings-overdue-date">
      <label
        class="op-reminder-settings-date-alerts--label"
        [textContent]="text.overdue"
        for="op-settings-overdue-date"
      ></label>
      <select
        formControlName="time"
        class="op-reminder-settings-date-alerts--time form--select -narrow"
        data-qa-global-notification-type="op-reminder-settings-overdue-alerts"
        required="true"
        *ngIf="dateAlertsStatuses.overdue"
      >
        <option
          *ngFor="let availableTime of availableTimesOverdue"
          [value]="availableTime.value">
          {{availableTime.title}}
        </option>
      </select>
    </div>
  </div>

  <op-enterprise-banner
    *ngIf="eeShowBanners"
    [moreInfoLink]="text.more_info_link"
    [collapsible]="true"
    [textMessage]="text.teaser_text"
    opReferrer="notifications#date-alerts"
    class="op-date-alert-ee-banner">
  </op-enterprise-banner>


  <div class="op-form--section-header">
    <h3 class="op-form--section-header-title">{{ text.alsoNotifyFor.title }}</h3>
    <p>{{ text.alsoNotifyFor.description }}</p>
  </div>

  <spot-selector-field
    [label]="text.work_package_created"
    [control]="form.get('workPackageCreated')">
    <input
      slot="input"
      type="checkbox"
      formControlName="workPackageCreated"
      data-qa-global-notification-type="work_package_created"
    />
  </spot-selector-field>

  <spot-selector-field
    [label]="text.work_package_processed"
    [control]="form.get('workPackageProcessed')">
    <input
      slot="input"
      type="checkbox"
      formControlName="workPackageProcessed"
      data-qa-global-notification-type="work_package_processed"
    />
  </spot-selector-field>

  <spot-selector-field
    [label]="text.work_package_scheduled"
    [control]="form.get('workPackageScheduled')">
    <input
      slot="input"
      type="checkbox"
      formControlName="workPackageScheduled"
      data-qa-global-notification-type="work_package_scheduled"
    />
  </spot-selector-field>

  <spot-selector-field
    [label]="text.work_package_prioritized"
    [control]="form.get('workPackagePrioritized')">
    <input
      slot="input"
      type="checkbox"
      formControlName="workPackagePrioritized"
      data-qa-global-notification-type="work_package_prioritized"
    />
  </spot-selector-field>

  <spot-selector-field
    [label]="text.work_package_commented"
    [control]="form.get('workPackageCommented')">
    <input
      slot="input"
      type="checkbox"
      formControlName="workPackageCommented"
      data-qa-global-notification-type="work_package_commented"
    />
  </spot-selector-field>


  <div class="op-form--section-header">
    <h3 class="op-form--section-header-title">{{ text.projectSpecific.title }}</h3>
    <p>{{text.projectSpecific.description}}</p>
  </div>

  <op-notification-settings-table
    *ngIf="userId"
    [userId]="userId"
    [settings]="form.controls.projectSettings"
    formArrayName="projectSettings"
    class="op-notification-settings-page--table"
  ></op-notification-settings-table>

  <div class="generic-table--action-buttons">
    <button
      class="button -primary"
      [textContent]="text.save"
      type="submit"
    ></button>
  </div>
</form>
