// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';
import { I18nService } from 'core-app/core/i18n/i18n.service';
import { IProject } from 'core-app/core/state/projects/project.model';
import { AbstractWidgetComponent } from 'core-app/shared/components/grids/widgets/abstract-widget.component';
import { Subscription, take } from 'rxjs';

@Component({
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetProjectsListComponent extends AbstractWidgetComponent implements OnInit, OnDestroy {

  constructor(
    protected readonly i18n: I18nService,
    protected readonly injector: Injector,
    protected readonly cdr: ChangeDetectorRef,
    public readonly apiV3Service: ApiV3Service,
  ) {
    super(i18n, injector);
  }

  public projectList: IProject[];

  ngOnInit(): void {
    this.apiV3Service.projects.listActive().pipe(take(1)).subscribe(
      (projects) => {
        this.projectList = projects.$source._embedded.elements;
        this.cdr.detectChanges();
      },
    );
  }

  ngOnDestroy(): void {
  }
}
