// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import { DateFactDisplayField } from "./date-fact-display-field.module";

export class CombinedFactDateDisplayField extends DateFactDisplayField {
  text = {
    placeholder: {
      factStartDate: this.I18n.t('js.label_no_start_date'),
      factDueDate: this.I18n.t('js.label_no_due_date'),
      factDate: this.I18n.t('js.label_no_date'),
    },
  };

  public render(element:HTMLElement):void {
    if (this.name === 'factDate') {
      this.renderSingleDate('factDate', element);
      return;
    }

    if (this.startDate && (this.startDate === this.dueDate)) {
      this.renderSingleDate('factDueDate', element);
      return;
    }

    if (!this.startDate && !this.dueDate) {
      element.innerHTML = this.customPlaceholder(`${this.text.placeholder.factStartDate} - ${this.text.placeholder.factDueDate}`);
      return;
    }

    this.renderDates(element);
  }

  isEmpty():boolean {
    return false;
  }

  customPlaceholder(fallback:string):string {
    if (typeof this.context.options.placeholder === 'string') {
      return this.context.options.placeholder;
    }

    return fallback;
  }

  private get startDate():string|null {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
    return this.resource.factStartDate;
  }

  private get dueDate():string|null {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
    return this.resource.factDueDate;
  }

  private renderSingleDate(field:'factDate'|'factStartDate'|'factDueDate', element:HTMLElement):void {
    element.innerHTML = '';

    const dateElement = this.createDateDisplayField(field);

    element.appendChild(dateElement);
  }

  private renderDates(element:HTMLElement):void {
    element.innerHTML = '';

    const startDateElement = this.createDateDisplayField('factStartDate');
    const dueDateElement = this.createDateDisplayField('factDueDate');

    const separator = document.createElement('span');
    separator.textContent = ' - ';

    element.appendChild(startDateElement);
    element.appendChild(separator);
    element.appendChild(dueDateElement);
  }

  private createDateDisplayField(date:'factDueDate'|'factStartDate'|'factDate'):HTMLElement {
    const dateElement = document.createElement('span');
    const dateDisplayField = new DateFactDisplayField(date, this.context);
    dateDisplayField.apply(this.resource, this.schema);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const text = this.resource[date]
      ? dateDisplayField.valueString
      : this.customPlaceholder(this.text.placeholder[date]);
    dateDisplayField.render(dateElement, text);

    return dateElement;
  }
}
