// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import { OPContextMenuService } from 'core-app/shared/components/op-context-menu/op-context-menu.service';
import { Directive, ElementRef } from '@angular/core';
import { OpContextMenuTrigger } from 'core-app/shared/components/op-context-menu/handlers/op-context-menu-trigger.directive';
import { I18nService } from 'core-app/core/i18n/i18n.service';
import { BoardService } from 'core-app/features/boards/board/board.service';
import { CurrentProjectService } from 'core-app/core/current-project/current-project.service';
import { OpContextMenuItem } from '../op-context-menu.types';
import { ToastService } from '../../toaster/toast.service';

@Directive({
  selector: '[wpTypeViewDropdown]',
})

export class WpTypeViewDropdownMenuDirective extends OpContextMenuTrigger {
  protected currentProject: string | null;
  protected firstBoard: string | null;

  public isOpen = false;

  constructor(
    readonly elementRef: ElementRef,
    readonly opContextMenu: OPContextMenuService,
    readonly I18n: I18nService,
    readonly boardService: BoardService,
    readonly currentProjectService: CurrentProjectService,
    readonly toastService: ToastService,
  ) {
    super(elementRef, opContextMenu);
    void this.getData();
  }

  async getData() {
    this.currentProject = this.currentProjectService.path;

    const boards = await this.boardService.loadAllBoards();
    this.firstBoard = boards?.[0]?.id;
  }

  protected open(evt: JQuery.TriggeredEvent) {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.currentProject == null
        ? this.toastService.addWarning(`Проект не выбран`)
        : this.buildItemsNew();

      this.opContextMenu.show(this, evt);
      this.isOpen = false;
    } else {
      this.opContextMenu.close();
      this.isOpen = true;
    }
  }

  public get locals() {
    return {
      items: this.items,
      contextMenuId: 'wp-view-context-menu',
    };
  }

  private buildItemsNew() {
    this.items = [];

    if (window.location.href.includes('work_packages')) {
      this.items = [this.gantt, this.boards];
      return;
    }
    if (window.location.href.includes('gantt')) {
      this.items = [this.list, this.boards];
      return;
    }
    if (window.location.href.includes('/boards/')) {
      this.items = [this.list, this.gantt];
      return;
    }
  }

  get list(): OpContextMenuItem {
    return {
      // List View
      linkText: this.I18n.t('js.type_views.wp'),
      title: this.I18n.t('js.button_show_table'),
      onClick: () => {
        window.location.href = `${this.currentProject}/work_packages?${this.currentProject}`;
        return true;
      }
    }
  }

  get gantt(): OpContextMenuItem {
    return {
      // List View with enabled Gantt
      linkText: this.I18n.t('js.type_views.gantt'),
      title: this.I18n.t('js.button_show_gantt'),
      onClick: () => {
        window.location.href = `${this.currentProject}/gantt`;
        return true;
      }
    }
  }

  get boards(): OpContextMenuItem {
    return {
      linkText: this.I18n.t('js.type_views.kanban'),
      title: this.I18n.t('js.button_show_kanban'),
      onClick: () => {
        window.location.href = `${this.currentProject}/boards/${this.firstBoard}`;
        return true;
      }
    }
  }
}

