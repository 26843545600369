// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { I18nService } from "core-app/core/i18n/i18n.service";
import { LoadingIndicatorService } from "core-app/core/loading-indicator/loading-indicator.service";
import { GroupedItem, IRequestActivity } from "core-app/core/state/activity/activity.model";
import { ActivityResourceService } from "core-app/core/state/activity/activity.service";
import { CollectionResource } from "core-app/features/hal/resources/collection-resource";
import { AbstractWidgetComponent } from "core-app/shared/components/grids/widgets/abstract-widget.component";
import { ToastService } from "core-app/shared/components/toaster/toast.service";
import moment from "moment";

@Component({
  templateUrl: "./activity.component.html",
  styleUrls: ['./activity.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetActivityComponent extends AbstractWidgetComponent implements OnInit, OnDestroy {
  constructor(
    protected readonly i18n: I18nService,
    protected readonly injector: Injector,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly activityService: ActivityResourceService,
    protected readonly toastService: ToastService,
    protected readonly loadingIndicator: LoadingIndicatorService
  ) {
    super(i18n, injector);
  }
  protected statusIcon: string;
  public message: string;
  public status: string;
  public emptySelected: boolean;

  protected activityResurses: CollectionResource<IRequestActivity>

  protected activity: IRequestActivity;

  protected result: GroupedItem[] = [];

  ngOnInit(): void {
    this.getActivity();
  }

  ngOnDestroy(): void { }

  private getActivity(params?: string[]) {
    this.result = [];
    this.emptySelected = false;
    this.loadingIndicator.indicator('events').start();

    this.activityService.list(params)
      .subscribe({
        next: (collection) => {
          const activity = collection.$source as IRequestActivity;
          this.result = this.activityService.getGroupedActivity(activity);
          this.result = this.sortItemsByDate(this.result);
          this.cdr.detectChanges();
        },
        error: (error: HttpErrorResponse) => {this.loadingIndicator.indicator('events').stop(); this.toastService.addError(error)},
        complete: () => {
          this.loadingIndicator.indicator('events').stop();
          this.emptySelected = this.result.length === 0;
        },
      });
  }

  sortItemsByDate(array: GroupedItem[]) {
    return array.sort((a, b) => moment(a.date, "DD.MM.YYYY").isAfter(moment(b.date, "DD.MM.YYYY")) ? -1 : 1);
  }

  beforePanelClosed(panel: any) {
    panel.isExpanded = false;
  }

  beforePanelOpened(panel: any) {
    panel.isExpanded = true;
  }
}
