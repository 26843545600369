// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';
import { CustomActionResource } from 'core-app/features/hal/resources/custom-action-resource';
import { HalEventsService } from 'core-app/features/hal/services/hal-events.service';
import {
  HalResourceEditingService,
} from 'core-app/shared/components/fields/edit/services/hal-resource-editing.service';
import { HalResourceService } from 'core-app/features/hal/services/hal-resource.service';
import { ResourceChangeset } from 'core-app/shared/components/fields/changeset/resource-changeset';
import {
  WorkPackageNotificationService,
} from 'core-app/features/work-packages/services/notifications/work-package-notification.service';
import { WorkPackageResource } from 'core-app/features/hal/resources/work-package-resource';
import {
  WorkPackagesActivityService,
} from 'core-app/features/work-packages/components/wp-single-view-tabs/activity-panel/wp-activity.service';
import { UntilDestroyedMixin } from 'core-app/shared/helpers/angular/until-destroyed.mixin';
import { OpModalService } from 'core-app/shared/components/modal/modal.service';
import { SaveQueryModalComponent } from 'core-app/shared/components/modals/save-modal/save-query.modal';
import moment from 'moment';
import { AttachmentsResourceService } from 'core-app/core/state/attachments/attachments.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'core-app/shared/components/toaster/toast.service';
import { I18nService } from 'core-app/core/i18n/i18n.service';
import { WorkPackagesListService } from '../../wp-list/wp-list.service';
import { HalResourceNotificationService } from 'core-app/features/hal/services/hal-resource-notification.service';
import { QueryResource } from 'core-app/features/hal/resources/query-resource';
import { LoadingIndicatorService } from 'core-app/core/loading-indicator/loading-indicator.service';
import { firstValueFrom, lastValueFrom, take } from 'rxjs';

enum EFileInputKey {
  pauseProject = 'Приостановить проект',
  sanctions = 'Влияние санкций',
  teo = 'Влияние ТЭО',
  resumeProject = 'Возобновить проект',
  stopProject = 'Прекратить проект',
}

enum EFinishAction {
  finish = 'Завершить работу',
  finishWithFile = 'Завершить, приложив файл',
}

@Component({
  selector: 'wp-custom-action',
  templateUrl: './wp-custom-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class WpCustomActionComponent extends UntilDestroyedMixin implements OnInit {
  @Input() workPackage: WorkPackageResource;
  @Input() isFileNeeded: boolean;
  @Input() isFieldFilled: (field: string) => boolean;
  @Input() action: CustomActionResource;
  @ViewChild('hiddenFileInput') public filePicker: ElementRef<HTMLInputElement>;
  constructor(
    public readonly injector: Injector,
    protected readonly I18n: I18nService,
    private halResourceService: HalResourceService,
    private loadingIndicatorService: LoadingIndicatorService,
    private apiV3Service: ApiV3Service,
    private wpActivity: WorkPackagesActivityService,
    private notificationService: WorkPackageNotificationService,
    protected readonly attachmentsResourceService: AttachmentsResourceService,
    private halEditing: HalResourceEditingService,
    private halEvents: HalEventsService,
    private cdRef: ChangeDetectorRef,
    protected readonly toastService: ToastService,
    private wpListService: WorkPackagesListService,
    private readonly halNotification: HalResourceNotificationService,
  ) {
    super();
  }

  isFileInput = false;
  hidden = false;

  public text = {
    attachments: this.I18n.t('js.label_attachments'),
    foldersWarning: this.I18n.t('js.label_drop_folders_hint'),
    sanctionsDurationError: 'Поле "Длительность санкций" должно быть заполнено.',
    sanctionsEndDateError: 'Поле "Дата окончания санкций" должно быть заполнено.',
    sanctionsStartDateError: 'Поле "Дата начала санкций" должно быть заполнено.',
    teoDurationError: 'Поле "Длительность ТЭО" должно быть заполнено.',
    teoStartDateError: 'Поле "Дата начала ТЭО" должно быть заполнено.',
    teoEndDateError: 'Поле "Дата окончания ТЭО" должно быть заполнено.',
    pauseEndDateError: 'Поле "Дата окончания приостановки" должно быть заполнено.',
    pauseStartDateError: 'Поле "Дата начала приостановки" должно быть заполнено.',
    pauseDurationError: 'Поле "Длительность приостановки" должно быть заполнено.',
    nameError: 'Имя файла должно начинаться со слова "Обоснование".',
    factError: 'Произошла ошибка. Нет синхронизации с Primavera.',
    factSuccess: 'Успешно синхронизировано.'
  };

  ngOnInit() {
    this
      .halEvents
      .events$
      .pipe(
        this.untilDestroyed(),
      )
      .subscribe(() => this.cdRef.detectChanges());
    this.isFileInput = this.defineIsFileInput(this.action.name);

    if (this.action.name === EFinishAction.finish) {
      this.hidden = this.isFileNeeded;
      this.cdRef.detectChanges();
    }

    if (this.action.name === EFinishAction.finishWithFile) {
      this.hidden = !this.isFileNeeded;
      this.cdRef.detectChanges();
    }
  }

  private fetchAction() {
    if (this.action.href === null) return;

    void this.halResourceService
      .get<CustomActionResource>(this.action.href)
      .subscribe((action) => {
        this.action = action;
      });
  }

  public get change(): ResourceChangeset<WorkPackageResource> {
    return this.halEditing.changeFor(this.workPackage);
  }

  public onFilePickerChanged(): void {
    const fileList = this.filePicker.nativeElement.files;
    if (fileList === null) return;

    this.uploadFiles(Array.from(fileList));
    this.filePicker.nativeElement.value = '';
  }

  protected uploadFiles(files: File[]): void {
    let filesWithoutFolders = files || [];
    const countBefore = files.length;
    filesWithoutFolders = this.filterFolders(filesWithoutFolders);

    if (filesWithoutFolders.length === 0) {
      if (countBefore > 0) {
        this.toastService.addNotice(this.text.foldersWarning);
      }
      return;
    }
    if (!filesWithoutFolders[0].name.startsWith('Обоснование')) {
      this.toastService.addError(this.text.nameError);
      return;
    }

    this
      .attachmentsResourceService
      .attachFiles(this.workPackage, filesWithoutFolders)
      .subscribe({
        next: () => { void this.executeAction(); },
        error: (error: HttpErrorResponse) => this.toastService.addError(error),
      });
  }

  protected filterFolders(files: File[]): File[] {
    return files.filter((file) => {
      if (file.type !== '') {
        return true;
      }
      if (file.size === 0 || file.size === 4096) {
        console.warn(`Skipping file because of file size (${file.size}) %O`, file);
        return false;
      }

      return true;
    });
  }

  defineIsFileInput(key: unknown): key is EFileInputKey {
    return Object.values(EFileInputKey).includes(key as EFileInputKey) || key === EFinishAction.finishWithFile;
  }

  public async executeAction(): Promise<void> {
    const payload = {
      lockVersion: this.workPackage.lockVersion,
      _links: {
        workPackage: {
          href: this.workPackage.previewPath(),
        },
      },
    };

    // Mark changeset as in flight
    this.change.inFlight = true;

    this.halResourceService
      .post<WorkPackageResource>(`${this.action.href}/execute`, payload)
      .subscribe(
        (savedWp: WorkPackageResource) => {
          this.notificationService.showSave(savedWp, false);
          this.workPackage = savedWp;
          this.wpActivity.clear(this.workPackage.id);
          void this.apiV3Service.work_packages.cache.updateWorkPackage(savedWp).then(() => {
            this.halEditing.stopEditing(savedWp);
            this.halEvents.push(savedWp, { eventType: 'updated' });
            this.change.inFlight = false;
            this.cdRef.detectChanges();
          });
        },
        (errorResource) => {
          this.notificationService.handleRawError(errorResource, this.workPackage);
          this.change.inFlight = false;
          this.cdRef.detectChanges();
        },
      );
  }

  private async syncWithPrimavera(direction:string) {

    const request$ = this.apiV3Service.work_packages.http.get(`/projects/${this.workPackage.project?.id}/primavera/${direction}`).pipe(take(1));
    this.toastService.clear();
    const promise = firstValueFrom(request$).then((_response) => { this.toastService.addSuccess(this.text.factSuccess) }).catch((_error) => { this.toastService.addError(this.text.factError)});
    this.loadingIndicatorService.wpDetails.promise = promise;
  }

  public async update() {
    if (this.action?.name === 'План из Primavera') {
      await this.syncWithPrimavera('from');
      return;
    }

    if (this.action?.name === 'Факт в Primavera') {
      await this.syncWithPrimavera('to');
      return;
    }

    if(this.action.name === EFileInputKey.sanctions){
      let errors = [];
      if(!this.isFieldFilled('dlitielnost-sanktsii')){
        errors.push(this.text.sanctionsDurationError);
      }
      if(!this.isFieldFilled('data-okonchaniia-sanktsii')){
        errors.push(this.text.sanctionsEndDateError);
      }
      if(!this.isFieldFilled('data-nachala-sanktsii')){
        errors.push(this.text.sanctionsStartDateError);
      }
      if(errors.length > 0){
        this.toastService.addError(errors.join('\n'));
        return;
      }
    }

    if(this.action.name === EFileInputKey.teo){
      let errors = [];
      if(!this.isFieldFilled('dlitielnost-teo')){
        errors.push(this.text.teoDurationError);
      }
      if(!this.isFieldFilled('data-okonchaniia-teo')){
        errors.push(this.text.teoEndDateError);
      }
      if(!this.isFieldFilled('data-nachala-teo')){
        errors.push(this.text.teoStartDateError);
      }
      if(errors.length > 0){
        this.toastService.addError(errors.join('\n'));
        return;
      }
    }

    if(this.action.name === EFileInputKey.pauseProject){
      let errors = [];
      if(!this.isFieldFilled('dlitielnost-priostanovki')){
        errors.push(this.text.pauseDurationError);
      }
      if(!this.isFieldFilled('data-okonchaniia-priostanovki')){
        errors.push(this.text.pauseEndDateError);
      }
      if(!this.isFieldFilled('data-nachala-priostanovki')){
        errors.push(this.text.pauseStartDateError);
      }
      if(errors.length > 0){
        this.toastService.addError(errors.join('\n'));
        return;
      }
    }
    if (this.isFileInput) {
      this.filePicker.nativeElement.click();
      return;
    }

    if (this.action.href === null) return;

    void this.executeAction();
  }

  @HostListener('mouseenter') onMouseEnter(): void {
    this.fetchAction();
  }
}
