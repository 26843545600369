<button
    *ngIf="!hidden"
    (click)="update()"
    class="custom-action--button button -narrow hide-when-print"
    title="{{action.description ? action.description : action.name}}"
    [disabled]="change.inFlight"
    >
  {{action.name}}
  <input
  *ngIf="isFileInput && workPackage.canAddAttachments"
  #hiddenFileInput
  type="file"
  id="attachment_files"
  name="attachment_files"
  (change)="onFilePickerChanged()"
  hidden
/>
</button>

