// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import { Injectable } from '@angular/core';
import { GroupActivity, GroupedItem, GroupEvents, IActivity, IRequestActivity, Project } from './activity.model';
import {
  ResourceStore,
  ResourceStoreService,
} from 'core-app/core/state/resource-store.service';
import { ActivityStore } from './activity.store';
import { HalResourceService } from 'core-app/features/hal/services/hal-resource.service';
import { InjectField } from 'core-app/shared/helpers/angular/inject-field.decorator';
import { CollectionResource } from 'core-app/features/hal/resources/collection-resource';
import { Observable } from 'rxjs';
import moment from 'moment';

export function numberToTime(decimal: number): string {
  const hours = Math.floor(decimal);
  const minutes = Math.round((decimal - hours) * 60);
  return `${hours.toString()} ч ${minutes.toString().padStart(2, '0')} мин`;
}

export function getLastElementURL(url: string): string {
  const lastIndex = url.lastIndexOf("/");
  return url.substring(lastIndex + 1);
}

@Injectable()
export class ActivityResourceService extends ResourceStoreService<IActivity> {
  protected createStore(): ResourceStore<IActivity> {
    return new ActivityStore();
  }

  @InjectField() halResourceService: HalResourceService;

  protected groupedItems: GroupEvents[] = [];

  protected groups: GroupActivity[] = [];

  protected result: GroupedItem[] = [];

  protected basePath(): string {
    return this.apiV3Service.pathHelper.activitePath;
  }

  public list(params?: string[], date?: string): Observable<CollectionResource<IRequestActivity>> {
    return this
      .halResourceService
      .get<CollectionResource<any>>(this.basePath() + this.listParamsString(params, date));
  }

  reset(){
    this.groupedItems = [];
    this.groups = [];
    this.result =[];
  }

  getGroupedActivity(activity: IRequestActivity): GroupedItem[] {
    this.reset();

    for (const key in activity) {
      if (key.includes('=>')) {
        const keyNew = activity[key][0].project.identifier;
        const projectName = activity[key][0].project.name;

        for (const item of activity[key]) {
          const time = moment.utc(item.event_datetime, 'YYYY-MM-DDTHH:mm:ss.SSSZZ');
          item.datetime = time.format('DD.MM.YYYY HH:mm');
          item.event_datetime = time.format('DD.MM.YYYY');
        }
        this.groupedItems = this.groupBy(activity[key], 'event_datetime');
        this.groups.push({ project: { key: keyNew, name: projectName }, items: this.groupedItems });

      }
    }
    this.result = this.groupByDateAndProject(this.groups);
    return this.result;
  }

  groupBy(array: any[], property: string): { date: string; events: IActivity[] }[] {
    return Object.values(
      array.reduce((res, obj) => {
        const key = obj[property];
        if (!res[key]) {
          res[key] = { date: obj[property], events: [] };
        }
        res[key].events.push(obj);
        return res;
      }, {})
    );
  }

  groupByDateAndProject(array: { project: Project; items: GroupEvents[] }[]): GroupedItem[] {
    const groupedByDate: { [dateKey: string]: GroupedItem } = {};
    array.forEach((group) => {
      group.items.forEach((eventItem) => {
        const dateKey = eventItem.date;

        if (!groupedByDate[dateKey]) {
          groupedByDate[dateKey] = { date: dateKey, projects: [] };
        }
        const projectIndex = groupedByDate[dateKey].projects.findIndex(
          (projectGroup) => projectGroup.project.key === group.project.key
        );
        if (projectIndex === -1) {
          groupedByDate[dateKey].projects.push({
            project: group.project,
            events: eventItem.events
          });
        } else {
          groupedByDate[dateKey].projects[projectIndex].events.push(
            ...eventItem.events
          );
        }
      });
    });
    return Object.values(groupedByDate);
  }


  public listParamsString(params?: string[], date?: string): string {
    const queryProps = [];

    if (date) {
      queryProps.push(`from=${date}`);
    }

    queryProps.push('with_subprojects=1');

    params?.forEach((item) => {
      queryProps.push(`event_types[]=${item}`);
    })

    let queryPropsString = '';
    if (queryProps.length) {
      queryPropsString = `?${queryProps.join('&')}`;
    }

    return queryPropsString;
  }
}
