<widget-header
    [name]="widgetName"
    (onRenamed)="renameWidget($event)">

  <widget-menu
    slot="menu"
    [resource]="resource">
  </widget-menu>
</widget-header>

<wp-overview-graph
    class='grid--widget-content -no-overflow'
    [groupBy]="'type'"
    [isMyPage]="isMyPage">
</wp-overview-graph>
