<div class="generic-container">
    <div class="head-title">
        <h3>{{headerDate}}</h3>
    </div>
    <div class="multi-checkbox">
        <multi-checkbox [options]="options" [(selectedOptions)]="selectedValues"
            (selectedOptionsChange)="onSelectedOptionsChange()">
        </multi-checkbox>
    </div>
    <div class="group-button">
        <button class="event-button" (click)="selectedAll()">Выбрать все</button>
        <button class="event-button" (click)="resetSelection()">Сброс фильтра</button>       
    </div>
    <div class="event-datepicker">
        <event-datepicker [(selectedDate)]="filterDate" (dateChange)="onDateChange($event)"></event-datepicker>
    </div>
    <div class="group-button res">
        <button class="event-button res" (click)="fetch()">Применить</button>
    </div>
    <div>
        <div class="loading-indicator--location" data-indicator-name="events">
        </div>
        <div class="empty-result" *ngIf="emptySelected">           
            <span>Событий нет</span>
            <svg width="22px" height="22px" viewBox="0 0 24 24" stroke="#008C95" stroke-width="0.5">
                <g id="SVGRepo-bg" stroke-width="0"></g>
                <g id="SVGRepo-tracer" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo-icon">
                    <path
                        d="M20,6.52797748 L20,19.5 C20,20.8807119 18.8807119,22 17.5,22 L6.5,22 C5.11928813,22 4,20.8807119 4,19.5 L4,4.5 C4,3.11928813 5.11928813,2 6.5,2 L15.4720225,2 C15.6047688,1.99158053 15.7429463,2.03583949 15.8535534,2.14644661 L19.8535534,6.14644661 C19.9641605,6.25705373 20.0084195,6.39523125 20,6.52797748 Z M15,3 L6.5,3 C5.67157288,3 5,3.67157288 5,4.5 L5,19.5 C5,20.3284271 5.67157288,21 6.5,21 L17.5,21 C18.3284271,21 19,20.3284271 19,19.5 L19,7 L15.5,7 C15.2238576,7 15,6.77614237 15,6.5 L15,3 Z M16,3.70710678 L16,6 L18.2928932,6 L16,3.70710678 Z">
                    </path>
                </g>
            </svg>            
        </div>
        <div class="group" *ngFor="let res of result">
            <div class="group-data">
                <span>{{res.date}}:</span>
            </div>
            <mat-accordion multi>
                <mat-expansion-panel *ngFor="let area of res.projects" (opened)="beforePanelOpened(area)"
                    (closed)="beforePanelClosed(area)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <a class="area-title" href="/projects/{{area.project.key}}">{{area.project.name}}</a>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <div class="card" *ngFor="let card of area.events">
                            <div class="card-header">
                                <a class="card-header-title" href="{{card.event_path}}">
                                    {{card.event_title}}
                                </a>
                            </div>
                            <div class="card-row">
                                <p>{{card.event_name}}</p>
                                <div class="card-row-user">
                                    <p class="card-row-user-text">автор:</p>
                                    <a class="card-row-user-author"
                                        href="/users/{{card.event_author.id}}">{{card.event_author.firstname}}
                                        {{card.event_author.lastname}}</a>
                                    <p class="card-row-user-text">{{card.datetime}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>