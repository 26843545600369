<widget-header
  [name]="widgetName"
  [editable]="isEditable"
>

  <widget-menu
    slot="menu"
    [resource]="resource">
  </widget-menu>

</widget-header>
<div class="grid--widget-content">
  <op-no-results
    *ngIf="noEntries" 
    [title]="text.noResults"
  ></op-no-results>
  <ul class="widget-box--arrow-links">
    <li
      class="widget-box--arrow-multiline" 
      *ngFor="let news of entries"
    >
      <op-principal
        *ngIf="news.author"
        [principal]="news.author"
        [hideName]="true"
        class="widget-box--avatar news-author-avatar hidden-for-mobile"
      ></op-principal>
      <div class="widget-box--project">
        <a
          [href]="newsProjectPath(news)"
          [textContent]="newsProjectName(news)"
        ></a>:
        <a
          [href]="newsPath(news)"
          [textContent]="news.title"
        ></a>
      </div>
      <div
        *ngIf="news.author" 
        class="widget-box--author news-author"
        [innerHTML]="text.addedBy(news)"
      ></div>
      <p
        *ngIf="news.summary" 
        [innerHTML]="newsGetSummarySafe(news)"
        class="widget-box--additional-info"
      ></p>
    </li>
  </ul>
</div>