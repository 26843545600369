<widget-header [name]="widgetName" (onRenamed)="renameWidget($event)">
  <widget-menu slot="menu" [resource]="resource"> </widget-menu>
</widget-header>

<div class="generic-table--results-container">
  <table class="generic-table">
    <colgroup>
      <col opHighlightCol />
      <col opHighlightCol />
    </colgroup>
    <thead>
      <tr>
        <th>
          <div class="generic-table--sort-header-outer">
            <div class="generic-table--sort-header">
              <span>Заголовок</span>
            </div>
          </div>
        </th>
        <th>
          <div class="generic-table--sort-header-outer">
            <div class="generic-table--sort-header">
              <span>Проект</span>
            </div>
          </div>
        </th>
        <th>
          <div class="generic-table--sort-header-outer">
            <div class="generic-table--sort-header">
              <span>Тип встречи</span>
            </div>
          </div>
        </th>
        <th>
          <div class="generic-table--sort-header-outer">
            <div class="generic-table--sort-header">
              <span>Время</span>
            </div>
          </div>
        </th>
        <th>
          <div class="generic-table--sort-header-outer">
            <div class="generic-table--sort-header">
              <span>Длительность</span>
            </div>
          </div>
        </th>
        <th>
          <div class="generic-table--sort-header-outer">
            <div class="generic-table--sort-header">
              <span>Расположение</span>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="" *ngFor="let item of meetings">
        <td class="title">
          <a href="/meetings/{{ item.id }}" [textContent]="item.title"></a>
        </td>
        <td class="project_id">
          <a href="/projects/{{ item.project_id }}" [textContent]="item._links.project.title"></a>
        </td>
        <td class="type">
          <span [textContent]="item.meeting_type"></span>
        </td>
        <td class="startTime">
          <span [textContent]="item._startTime"></span>
        </td>
        <td class="_duration">
          <span [textContent]="item._duration"></span>
        </td>
        <td class="location">
          <span [textContent]="item.location"></span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
