import { EntityStore, StoreConfig } from '@datorama/akita';
import { ResourceState, createInitialResourceState } from 'core-app/core/state/resource-store';
import { IMeeting } from './meeting.model';

export interface MeetingsState extends ResourceState<IMeeting> {
}

@StoreConfig({ name: 'meetings' })
export class MeetingsStore extends EntityStore<MeetingsState> {
  constructor() {
    super(createInitialResourceState());
  }
}
