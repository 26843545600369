<widget-header [name]="widgetName" (onRenamed)="renameWidget($event)">
  <widget-menu slot="menu" [resource]="resource"> </widget-menu>
</widget-header>

<div>
  <div class="loading-indicator--location" data-indicator-name="events">
    <div class="status-icon-wrapper" *ngIf="!emptySelected && statusIcon">
      <span [ngClass]="statusIcon" class="icon-big"></span>
    </div>
  </div>
  <div class="generic-container">
    <div class="empty-result" *ngIf="emptySelected">
      <p>Событий нет</p>
    </div>
    <div class="group" *ngFor="let res of result">
      <div class="group-data">
        <span>{{res.date}}:</span>
      </div>
      <mat-accordion multi>
        <mat-expansion-panel *ngFor="let area of res.projects" (opened)="beforePanelOpened(area)"
          (closed)="beforePanelClosed(area)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <a class="area-title" href="/projects/{{area.project.key}}">{{area.project.name}}</a>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <div class="card" *ngFor="let card of area.events">
              <div class="card-header">
                <a class="card-header-title" href="{{card.event_path}}">
                  {{card.event_title}}
                </a>
              </div>
              <div class="card-row">
                <p>{{card.event_name}}</p>
                <div class="card-row-user">
                  <p class="card-row-user-text">автор:</p>
                  <a class="card-row-user-author" href="/users/{{card.event_author.id}}">{{card.event_author.firstname}}
                    {{card.event_author.lastname}}</a>
                  <p class="card-row-user-text">{{card.datetime}}</p>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="link">
      <a class="link-title" href="/activities_events">Перейти в модуль "Деятельность"</a>
    </div>
  </div>
</div>