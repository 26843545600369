import { ChangeDetectorRef, Component, ComponentRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { GridResource } from 'core-app/features/hal/resources/grid-resource';
import { DomSanitizer } from '@angular/platform-browser';
import { GridWidgetsService } from 'core-app/shared/components/grids/widgets/widgets.service';
import { GridArea } from 'core-app/shared/components/grids/areas/grid-area';
import { GridDragAndDropService } from 'core-app/shared/components/grids/grid/page-service/drag-and-drop.service';
import { GridResizeService } from 'core-app/shared/components/grids/grid/page-service/resize.service';
import { GridAreaService } from 'core-app/shared/components/grids/grid/page-service/area.service';
import { GridAddWidgetService } from 'core-app/shared/components/grids/grid/page-service/add-widget.service';
import { WidgetWpGraphComponent } from 'core-app/shared/components/grids/widgets/wp-graph/wp-graph.component';
import { GridWidgetArea } from 'core-app/shared/components/grids/areas/grid-widget-area';
import { GridRemoveWidgetService } from './page-service/remove-widget.service';
import { BrowserDetector } from 'core-app/core/browser/browser-detector.service';
import { combineLatest, Observable, take } from 'rxjs';
import { GridWidgetResource } from 'core-app/features/hal/resources/grid-widget-resource';
import { ApiV3Service } from 'core-app/core/apiv3/api-v3.service';
import {
  columnsProjects, statusClose, statusEnd, typeKP, statusStart, columnsStage, columnsConf, proejctTarget,
  workerProjects,
  workerStage,
  managerInWork,
  managerWidgets,
  workerWidgets,
  managerStageStatus,
  managerStage,
  workerConference,
  managerConference,
  customFiledManager,
  customFiledConf,
  proejctMain,
  customFiledIdWork
} from './config-lk-widget';
import { HalResourceService } from 'core-app/features/hal/services/hal-resource.service';
import { StatusResource } from 'core-app/features/hal/resources/status-resource';
import { CollectionResource } from 'core-app/features/hal/resources/collection-resource';
import { TypeResource } from 'core-app/features/hal/resources/type-resource';
import { QueryResource } from 'core-app/features/hal/resources/query-resource';
import { GridGap } from '../areas/grid-gap';
import { ToastService } from '../../toaster/toast.service';
import { ProjectResource } from 'core-app/features/hal/resources/project-resource';
import { ConfigQueryService } from 'core-app/core/apiv3/endpoints/queries/query-service';
import { ProjectsResourceService } from 'core-app/core/state/projects/projects.service';
import { WidgetConfig } from './grid-const-helper';

@Component({
  templateUrl: './grid.component.html',
  selector: 'grid',
})
export class GridComponent implements OnDestroy, OnInit {
  public uiWidgets: ComponentRef<any>[] = [];

  public GRID_AREA_HEIGHT = 'auto';
  public GRID_GAP_DIMENSION = '20px';

  public component = WidgetWpGraphComponent;

  @Input() grid: GridResource;

  public types$: Observable<CollectionResource<TypeResource>>;
  public statuses$: Observable<CollectionResource<StatusResource>>;
  public projects$: Observable<CollectionResource<ProjectResource>>;
  public customs$: Observable<CollectionResource<string>>;

  private typeIds: number[] = [];
  private statusIds: number[] = [];
  private projectIds: number;

  private statusStartId: number;
  private statusByStage: number[] = [];

  private managerFieldIdSUOF: string;
  private managerFieldIdConf: string;
  private workerFieldIdWork: string;

  private queryProjects$: Observable<QueryResource>;
  private queryStage$: Observable<QueryResource>;
  private queryConf$: Observable<QueryResource>;

  private managerQueryInWork$: Observable<QueryResource>;
  private managerQueryConf$: Observable<QueryResource>;

  private isManager: boolean;
  private isLoadingRegistred: boolean = false;

  constructor(private sanitization: DomSanitizer,
    private widgetsService: GridWidgetsService,
    public drag: GridDragAndDropService,
    public resize: GridResizeService,
    public layout: GridAreaService,
    public add: GridAddWidgetService,
    public remove: GridRemoveWidgetService,
    private apiV3Service: ApiV3Service,
    private halResService: HalResourceService,
    private configWidgetService: ConfigQueryService,
    private toastService: ToastService,
    private projectsResourceService: ProjectsResourceService,
    readonly browserDetector: BrowserDetector,
    readonly cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.layout.gridResource = this.grid;
    window.location.pathname === '/my/page' ? this.widgetsMyPage() : this.isLoadingRegistred = true;
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key !== 'Escape') {
    } else if (this.drag.currentlyDragging) {
      this.drag.abort();
    } else if (this.resize.currentlyResizing) {
      this.resize.abort();
    }
  }

  widgetsMyPage() {
    this.apiV3Service.users.getCurrentUserIsManeger().subscribe((flag) => {
      this.toastService.addSuccess(flag ? "Виджеты руководителя" : "Виджеты сотрудника");
      this.configWidgetService.setIsManager(flag);
      this.isManager = flag;
      this.getConfigWidgets();
    });
  }

  getConfigWidgets(): void {
    this.types$ = this.apiV3Service.types.get().pipe(take(1));
    this.statuses$ = this.apiV3Service.statuses.get().pipe(take(1));
    this.projects$ = this.apiV3Service.projects.listActiveConf().pipe(take(1));

    combineLatest([this.types$, this.statuses$, this.projects$]).subscribe({
      next: ([types, statuses, projects]) => {
        this.getTypes(types);
        this.getStatus(statuses);
        this.getProject(projects);
      },
      complete: () => {
        this.setConfigWidget(this.isManager);
      },
      error: (error) => {
        this.toastService.addError('Error fetching data types, statuses, projects, read console');
        console.error('Error fetching data types, statuses, projects:', error);
      },
    });
  }

  setConfigWidget(flag: boolean) {
    this.projectsResourceService.getCustomFieldKeyByName(proejctMain, customFiledConf).pipe(take(1)).subscribe({
      next: (idConf) => {
        idConf != null ? this.managerSetGroupedConf(idConf) : this.waringGetFieldId(customFiledConf);
      },
      complete: () => {
        flag ? this.registredManagerWidgets() : this.registredWorkerWidgets();
      },
      error: (error) => {
        this.toastService.addWarning(`Ошибка при загрузке пользовательского поля: "${customFiledConf}"`);
        console.error('Error fetching id by customField:', customFiledConf, error);
      },
    });
  }

  private getTypes(types: CollectionResource<TypeResource>) {
    this.typeIds = types.elements
      .filter((type) => type.name === typeKP)
      .map((item) => item.$source.id) as number[];
  }

  private getStatus(statuses: CollectionResource<StatusResource>) {
    this.statusIds = statuses.elements
      .filter((status) => status.name === statusEnd || status.name === statusClose)
      .map((item) => item.$source.id) as number[];

    this.statusStartId = statuses.elements
      .filter((status) => status.name === statusStart)
      .map((item) => item.$source.id)[0] as number;

    this.statusByStage = managerStageStatus
      .reduce((ids: number[], name) => {
        const item = statuses.elements.find(item => item.$source.name === name);
        if (item) {
          ids.push(item.$source.id);
        }
        return ids;
      }, []);
  }

  private getProject(projects: CollectionResource<ProjectResource>) {
    this.projectIds = projects.elements
      .map((item) => item.$source.id)[0];
    if (this.projectIds == 0) {
      this.toastService.addWarning(`Проект не найден: ${proejctTarget}`);
      console.log(`Проект не найден: ${proejctTarget}`)
    }
    else {
      console.log(`Проект найден: ${proejctTarget}`)
    };
  }

  private managerSetGroupedConf(result: string) {
    this.managerFieldIdConf = `customField${result}`;
  };

  private managerSetGroupedSUOF(result: string) {
    this.managerFieldIdSUOF = `customField${result}`;
  };

  private workerSetGroupedIDWork(result: string) {
    this.workerFieldIdWork = `customField${result}`;
    columnsStage.splice(1, 0, this.workerFieldIdWork);
  };

  ngOnDestroy() {
    this.uiWidgets.forEach((widget) => widget.destroy());
    this.configWidgetService.destroyIsManager$();
  }

  public addWidget(area: GridWidgetArea | GridArea) {
    void this
      .add
      .widget(area)
      .then(() => this.cdRef.detectChanges());
  }

  private addWidgetList(array: WidgetConfig[]) {
    array.forEach((item) => {
      this.add.widgetNew(item.area, item.gap).then(() => this.cdRef.detectChanges());
    });
  }

  public widgetComponent(area: GridWidgetArea) {
    const { widget } = area;

    if (!widget) {
      return null;
    }

    const registration = this.widgetsService.registered.find((reg) => reg.identifier === widget.identifier);
    if (!registration) {
      // debugLog(`No widget registered with identifier ${widget.identifier}`);
      return null;
    }

    return registration.component;
  }

  public widgetComponentInput(area: GridWidgetArea) {
    return { resource: area.widget };
  }

  public widgetComponentOutput(area: GridWidgetArea) {
    return { resourceChanged: this.layout.saveWidgetChangeset.bind(this.layout) };
  }

  public get gridColumnStyle() {
    return this.gridStyle(this.layout.numColumns,
      `calc((100% - ${this.GRID_GAP_DIMENSION} * ${this.layout.numColumns + 1}) / ${this.layout.numColumns})`);
  }

  public get gridRowStyle() {
    return this.gridStyle(this.layout.numRows,
      this.GRID_AREA_HEIGHT);
  }

  public identifyGridArea(index: number, area: GridArea) {
    return area.guid;
  }

  public get isHeadersDisplayed() {
    return this.layout.isEditable;
  }

  public get isMobileDevice() {
    return this.browserDetector.isMobile;
  }

  private gridStyle(amount: number, itemStyle: string) {
    let style = '';
    for (let i = 0; i < amount; i++) {
      style += `${this.GRID_GAP_DIMENSION} ${itemStyle} `;
    }
    style += `${this.GRID_GAP_DIMENSION}`;
    return this.sanitization.bypassSecurityTrustStyle(style);
  }

  private registredWorkerWidgets() {
    this.projectsResourceService.getCustomFieldKeyByName(proejctMain, customFiledIdWork).pipe(take(1)).subscribe({
      next: (idWork) => {
        idWork != null ? this.workerSetGroupedIDWork(idWork) : this.waringGetFieldId(customFiledIdWork);
      },
      complete: () => {
        this.isLoadingRegistred = this.configWidgetService.registedWidgets(this.isManager, this.propsConf, this.propsSouf);
        this.getWorkerWidgets();
      },
      error: (error) => {
        this.toastService.addWarning(`Ошибка при загрузке пользовательского поля: "${customFiledIdWork}"`);
        console.error('Error fetching id by customField:', customFiledIdWork, error);
      },
    });
  }

  private getWorkerWidgets() {
    if (this.grid.widgets.length == 0) {
      this.workerWidgetsCreate();
    }
    else if (this.grid.widgets.length == 2) {
      this.remove.removeWidgets();
      window.location.reload();
    }
  }

  private registredManagerWidgets() {
    this.projectsResourceService.getCustomFieldKeyByName(proejctMain, customFiledManager).pipe(take(1)).subscribe({
      next: (idSuof) => {
        idSuof != null ? this.managerSetGroupedSUOF(idSuof) : this.waringGetFieldId(customFiledManager);
      },
      complete: () => {
        this.isLoadingRegistred = this.configWidgetService.registedWidgets(this.isManager, this.propsConf, this.propsSouf, this.propsStage, this.propsProjects);
        this.getManagerWidgets();
      },
      error: (error) => {
        this.toastService.addWarning(`Ошибка при загрузке пользовательского поля: "${customFiledManager}"`);
        console.error('Error fetching id by customField:', customFiledManager, error);
      },
    });
  }

  private getManagerWidgets() {
    if (this.grid.widgets.length == 0) {
      this.managerWidgetsCreate();
    }
    else if (this.grid.widgets.length == 2) {
      this.remove.removeWidgets();
      window.location.reload();
    }
  }

  private workerWidgetsCreate() {
    this.queryProjects$ = this.configWidgetService.createQuery(this.propsProjects).pipe(take(1));
    this.queryStage$ = this.configWidgetService.createQuery(this.propsStage).pipe(take(1));
    this.queryConf$ = this.configWidgetService.createQuery(this.propsConf).pipe(take(1));

    combineLatest([this.queryProjects$, this.queryStage$, this.queryConf$]).subscribe({
      next: ([queryProject, queryStage, queryConf]) => {
        workerProjects.config.options.queryId = queryProject.$source.id;
        workerStage.config.options.queryId = queryStage.$source.id;
        workerConference.config.options.queryId = queryConf.$source.id;
        this.addWorkerWidgets();
      },
      complete: () => {
        this.layout.rebuildAndPersist();
      },
      error: (error) => {
        this.getNotify();
        console.error('Error fetching worker widgets:', error);
      },
    });
  }

  private managerWidgetsCreate() {
    this.managerQueryInWork$ = this.configWidgetService.createQuery(this.propsSouf).pipe(take(1));
    this.managerQueryConf$ = this.configWidgetService.createQuery(this.propsConf).pipe(take(1));

    combineLatest([this.managerQueryInWork$, this.managerQueryConf$]).subscribe({
      next: ([queryInWork, queryConf]) => {
        managerInWork.config.options.queryId = queryInWork.$source.id;
        managerConference.config.options.queryId = queryConf.$source.id;
        this.addManagerWidgets();
      },
      complete: () => {
        this.layout.rebuildAndPersist();
      },
      error: (error) => {
        this.getNotify();
        console.error('Error fetching manager widgets:', error);
      },
    });
  }

  private addWorkerWidgets() {
    const settings = this.createConfigWidgets(workerWidgets);
    this.addWidgetList(settings);
  }

  private addManagerWidgets() {
    const settings = this.createConfigWidgets(managerWidgets);
    this.addWidgetList(settings);
  }

  private createConfigWidgets(array: any[]): WidgetConfig[] {
    return array.map((widget) => {
      const resurce = this.halResService.createHalResourceOfClass(GridWidgetResource, widget.config);
      const area = new GridWidgetArea(resurce);
      const gap = widget.gap ? new GridGap(area.startRow, area.endRow, area.startColumn, area.endColumn, widget.gap.type) : undefined;
      return { area: area, gap: gap };
    });
  }

  private getNotify() {
    this.toastService.addError(`Параметры отсутствуют в системе: ${typeKP}, ${statusStart}, ${statusEnd}, ${managerStageStatus[0]}, ${managerStageStatus[1]}, ${managerStageStatus[2]}`);
  }

  private waringGetFieldId(field: string) {
    console.warn(`CustomField: "${field}" get is null id`);
    this.toastService.addWarning(`Пользовательское поле: "${field}" отсутствует в системе`);
  }

  private get propsProjects(): { [key: string]: any } {
    const filters: any[] = [];

    filters.push({ type: { operator: '=', values: this.typeIds } });
    filters.push({ status: { operator: '!', values: this.statusIds } })

    const query = {
      'columns[]': columnsProjects,
      filters: JSON.stringify(filters),
      sortBy: JSON.stringify([['id', 'desc']]),
      showHierarchies: true,
    };
    return query;
  }

  private get propsStage(): { [key: string]: any } {
    const filters: any[] = [];

    filters.push({ status: { operator: '=', values: [this.statusStartId], } })
    filters.push({ dueDate: { operator: '<t+', values: [15], } });

    const query = {
      'columns[]': columnsStage,
      filters: JSON.stringify(filters),
      sortBy: JSON.stringify([['id', 'desc']]),
      showHierarchies: false,
      groupBy: 'project'
    };
    return query;
  }

  private get propsSouf(): { [key: string]: any } {
    const filters: any[] = [];

    filters.push({ type: { operator: '=', values: this.typeIds } });
    filters.push({ status: { operator: '=', values: this.statusByStage } })

    const query = {
      'columns[]': managerStage,
      filters: JSON.stringify(filters),
      sortBy: JSON.stringify([['id', 'desc']]),
      showHierarchies: false,
      groupBy: this.managerFieldIdSUOF
    };
    return query;
  }

  private get propsConf(): { [key: string]: any } {
    const filters: any[] = [];

    filters.push({ project: { operator: '=', values: this.projectIds } });

    const query = {
      'columns[]': columnsConf,
      filters: JSON.stringify(filters),
      sortBy: JSON.stringify([['dueDate', 'asc'], ['type', 'desc']]),
      showHierarchies: false,
      groupBy: this.managerFieldIdConf
    };
    return query;
  }
}
