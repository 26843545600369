import { ID } from "@datorama/akita";
import { DateTime } from "i18n-js";

export interface IMeeting {
  id: ID;
  title: string;
  location: string;
  lockVersion: number;
  meeting_type: string;
  startTime: DateTime
  endTime: DateTime;
  _startTime: string;
  _endTime: string;
  duration: number;
  _duration: string;
  createdAt: Date;
  updatedAt: Date;
  _links?:{
    self:{ href:string, title:string },
    author:{ href:string, title:string },
    project:{ href:string, title:string }
  }
  project_id: string | undefined;
}

export var MeetingTypes: { [key: string]: string } = {
  Meeting: "Класический",
  StructuredMeeting: "Динамический",
};
 